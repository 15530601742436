import { reactive, toRefs  } from "vue";
import api from '@/helpers/api'
import { env } from '@/configs'

const state = reactive({
  issues: [],
  error: null,
  loading: true
});

// /issues?assignee_id=5

export default function useIssues() {

  const getIssuesByAssignee = (params) => {
    api.get({
      url: env.GITLAB_API_URL + '/issues',
      data: params ? Object.assign({}, params) : ''
    }).then(response => {
        if(response.status === 200) {
          state.issues = response.data
          state.loading = false
        }
      })
      .catch(error => {
        console.log(error);
        state.error = true;
      })
      .finally(() => (
        state.loading = false
      ));
  }


  return {
    getIssuesByAssignee,
    ...toRefs(state)
  }
}

