<template>
<div>

  <div v-if="error">
    <Columns>
      <Column>
         <div class="notification is-danger">There is an error!</div>
      </Column>
    </Columns>
  </div>

  <Suspense v-else>

    <template #default>

      <Columns>
        <Column>
          <Columns>
            <Column>
              <button class="button is-success" @click.prevent="fetchIssues">Refresh</button>
            </Column>
          </Columns>

          <Columns>
            <Column>
                <issue-item
                    v-for="item in issues"
                      :key="item"
                      :issue="item"

                      @clickIssueHandle="toggleIssueDetail(item)"
                  >
                  <template v-slot:issue_action>

                    <Column customClass="is-2"  :class="[ isRunning ? 'is-running' : '', completed ? 'is-complete' : '' ]">
                        <time-tracking-actions :issue="item" class="is-right" />
                    </Column>

                  </template>
                </issue-item>

              </Column>
            </Columns>

        </Column>
      </Columns>
    </template>

    <template #fallback>
      <Columns>
        <Column>
          <Loading />
        </Column>
      </Columns>
    </template>
  </Suspense>

    <teleport to="body">
        <sidebar>
          <issue-detail-comp v-if="selectedIssue" :item="selectedIssue" />
        </sidebar>
    </teleport>
  </div>
</template>

<script>
import { onUnmounted, onMounted, defineAsyncComponent, reactive, toRefs } from "vue";
import useIssues from '@/use/useIssues'
import useTimeTracking from '@/use/useTimeTracking'
import { useUser } from "@/use/useUser";
import useGeneral from '@/use/useGeneral'

export default {

  components: {
    IssueItem: defineAsyncComponent(() => import('@/components/issues/IssueItem')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    Loading: defineAsyncComponent(() => import('@/components/general/Loading.vue')),
    Sidebar: defineAsyncComponent(() => import('@/components/general/Sidebar')),
    IssueDetailComp: defineAsyncComponent(() => import('@/components/issues/IssueDetailPanel')),
    TimeTrackingActions: defineAsyncComponent(()=> import('@/components/widgets/timeTracking/issueAction'))
  },
  setup () {
    const {
      loading,
      error,
      issues,
      getIssuesByAssignee,
    } = useIssues();

    const { issue, isRunning, completed  } = useTimeTracking();

    const { currentUser } = useUser()

    const { setSidebarHandle } = useGeneral();

    const state = reactive({
      selectedIssue: null
    })

    function toggleIssueDetail (payload) {
      state.selectedIssue = Object.assign({}, payload)
      setSidebarHandle(true)
    }

    onUnmounted(()=> {
      state.selectedIssue = null
    })

    function fetchIssues () {
       const _params = { assignee_id: currentUser.value.gitlab_id, state: 'opened', scope: 'all', with_labels_details: true, order_by: 'updated_at' }
       getIssuesByAssignee( _params );
    }

    onMounted(() => {
      fetchIssues()
    })

    return {
      issue,
      error,
      issues,
      isRunning,
      completed,
      loading,
      toggleIssueDetail,
      fetchIssues,
       ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
  .is-running, .is-complete {
    > div:not(.is-active) {
      display: none;
    }
  }
</style>